<template>
  <div class="avoir-facture-frais">
    <font-awesome-icon
      icon="file-medical"
      class="icon-style-color mr-1"
      title="Avoir sur Facture"
      v-if="permission"
      @click.prevent="openModal('AvoirFacture' + index)"
    />

    <b-modal
      no-close-on-backdrop
      title="Modifier list des produits"
      :ref="'AvoirFacture' + index"
      :id="'AvoirFacture' + index"
      :hide-footer="true"
      :hide-header="true"
      size="sm"
      modal-class="cutsom-modal-bootstrap custom-modal-template-frais-cdg custom-modal-template-w-60-p"
    >
      <div class="hader mb-2">
        <div class="titleSetting text-aligne-center col-10">
          Création Facture(s) Avoir(s) N° : {{ item.num }}
        </div>
        <div
          class="iconClose col-1"
          @click.prevent="hideModal('AvoirFacture' + index)"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <div class="sumilation-template-block-style" id="hmt_block_id">
        <form @submit.prevent="handleCreateAvoir">
          <b-col class="col-12 block-product-simulation mt-5">
            <!-- <b-row>
              <b-col class="col-5 text-center">
                <b>RÉDUCTION QUANTITÉ GLOBALE </b></b-col
              >
              <b-col class="col-3 text-center">
                <b-form-group
                  label=""
                  label-for="quantite-globale"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    v-model="qteGlobale"
                    min="0"
                    :max="maxglobal"
                    step="any"
                    type="number"
                    id="quantite-globale"
                    :disabled="computedDisabledInputGlobale"
                    @change="changeQteAvoirProducts"
                    oninput="validity.valid||(value < 0 || value.length==0? value = '' : value =value);"
                  ></b-form-input>
                  <div
                    class="error-message d-flex justify-content-center"
                    v-if="unite != '%'"
                  >
                    <div class="error">
                      Quantité globale à avoir doit être infèrieur ou égal à
                      {{ maxglobal.toFixed(2) }}
                    </div>
                  </div>
                </b-form-group></b-col
              >
            </b-row>    <b-row class="fields-input">

              <hr class="separate-line col-10 m-0 mb-2 ml-4" />
            </b-row>-->
          </b-col>
          <b-row>
            <b-col class="col-12 text-center" v-if="checkPermission('GFMTHDCA')"
              ><b-form-group
                label-cols-sm="5"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="7"
                label="Date de l'avoir : "
              >
                <date-picker
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                  :value="date"
                  class="inputDateTemplate"
                  @input="handleDateTimePicker"
                ></date-picker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="fields-input">
            <hr class="separate-line col-10 m-0 mb-2 ml-4" />
          </b-row>
          <b-col class="col-12 block-product-simulation">
            <b-row class="mb-3">
              <b-col class="col-5 text-center"> <b>Nom</b></b-col>
              <b-col class="col-2 text-center"><b> PU HT </b></b-col>
              <b-col class="col-2 text-center"><b>Unité</b></b-col>
              <b-col class="col-2 text-center"><b>Qté</b></b-col>
            </b-row>
            <b-row
              class="fields-input"
              v-for="(product, index) in produitsFactureFrais"
              :key="'product' + index"
            >
              <b-col class="col-5"
                ><b-form-group
                  label=""
                  label-for="title-produt"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="title-produt"
                    v-model="product.nom"
                    required
                    disabled
                  ></b-form-input> </b-form-group
              ></b-col>
              <b-col class="col-2"
                ><b-form-group
                  label=""
                  label-for="pu-ht"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="pu-ht"
                    v-model="product.pu_ht"
                    type="number"
                    step="any"
                    min="0"
                    required
                    :disabled="product.disabled"
                  ></b-form-input> </b-form-group
              ></b-col>

              <b-col class="col-2">
                <b-form-group
                  label=""
                  label-for="unite-product"
                  class="input-modal-champ"
                >
                  <b-form-select
                    autocomplete="off"
                    id="unite"
                    v-model="product.unite"
                    value-field="id"
                    text-field="name"
                    :disabled="product.disabled"
                    @change="checkConditionQuantite(product.id)"
                    :options="listUnite"
                    class="b-form-select-raduis"
                  ></b-form-select> </b-form-group></b-col
              ><b-col class="col-2">
                <b-form-group
                  label=""
                  label-for="qte-product"
                  class="input-modal-champ"
                >
                  <b-form-input
                    id="qte-product"
                    v-model="product.qte_apres"
                    :max="product.qte_apres_avoir"
                    type="number"
                    step="any"
                    min="0"
                    required
                    :disabled="product.qte_disabled"
                  ></b-form-input>
                  <div
                    class="error-message d-flex justify-content-center"
                    v-if="
                      product.qte_apres_avoir != null &&
                        product.qte_disabled == false
                    "
                  >
                    <div class="error">
                      Rest qte à avoir
                      {{
                        (
                          Math.round(product.qte_apres_avoir * 100) / 100
                        ).toFixed(2)
                      }}
                    </div>
                  </div>
                </b-form-group></b-col
              >
              <hr class="separate-line col-10 m-0 mb-2 ml-4" />
            </b-row>
          </b-col>
          <div class="form-actions mt-3 mb-5 text-aligne-center">
            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li
                      v-for="(e, index) in error"
                      :key="index"
                      style="color: red; list-style: none"
                    >
                      {{ e }}
                    </li>
                  </ul>
                  <pre v-else style="color: red">{{ error }}</pre>
                </div>
              </div>
            </div>
            <b-button
              type="submit"
              class="button-valide-style mt-3"
              size="sm"
              variant="success"
              :disabled="computedDisabledButton"
            >
              Valider
              <div v-if="loadingCreate" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </b-button>
            <div v-if="loading == true" class="loading-custom-template-block">
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  props: {
    permission: { required: true },
    item: { required: true },
    index: { required: true }
  },
  data() {
    return {
      date: null,
      listUnite: [
        { id: '', name: '' },
        { id: '%', name: '%' }
      ],
      loading: false,
      loadingCreate: false,
      error: [],
      disabledButton: false,
      qteGlobale: 0,
      qteGlobaleAvoir: 0,
      maxglobal: 0,
      unite: null
    };
  },
  computed: {
    ...mapGetters(['produitsFactureFrais', 'checkPermission']),
    computedDisabledButton() {
      let products = this.produitsFactureFrais.filter(
        item => item.disabled == false
      );
      if (products.length == 0) {
        return true;
      }
      return false;
    },
    computedDisabledInputGlobale() {
      let products = this.produitsFactureFrais.filter(
        item => item.disabled == false
      );
      if (products.length == 0) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions(['createAvoirMensuel', 'getProductsFraisFacture']),
    handleDateTimePicker(data) {
      this.date = data;
    },
    checkConditionQuantite(id) {
      this.produitsFactureFrais.map(item => {
        if (item.id == id) {
          if (item.unite == '') {
            item.qte_apres = 1;
            item.qte_disabled = true;
          } else {
            item.qte_apres = 0;
            item.qte_disabled = false;
          }
        }
      });
    },
    async openModal(ref) {
      let now = new Date();
      this.date = moment(now).format('YYYY-MM-DD');
      this.$refs[ref].show();
      this.loading = true;
      const response = await this.getProductsFraisFacture(this.item.id);
      if (response.success) {
        this.produitsFactureFrais.map(item => {
          this.qteGlobaleAvoir =
            this.qteGlobaleAvoir + parseFloat(item.qte_apres_avoir);
        });
        this.unite = this.produitsFactureFrais[0]?.unite;
        if (this.unite == '%') {
          this.maxglobal = 100;
        } else {
          this.maxglobal = parseFloat(this.qteGlobaleAvoir);
        }
        this.loading = false;
      }
    },
    changeQteAvoirProducts() {
      if (this.unite == '%') {
        let reductionGlobal = parseFloat(this.qteGlobale) / 100;
        if (parseFloat(this.qteGlobale) != 0) {
          if (parseFloat(this.qteGlobale) == 100) {
            this.produitsFactureFrais.forEach(element => {
              element.qte_apres = parseFloat(element.qte_apres_avoir);
            });
          } else {
            this.produitsFactureFrais.forEach(element => {
              element.qte_apres =
                parseFloat(element.qte_apres_avoir) -
                parseFloat(element.qte_apres_avoir) * reductionGlobal;
            });
          }
        } else {
          this.produitsFactureFrais.forEach(element => {
            element.qte_apres = parseFloat(element.initial_apres_avoir);
          });
        }
      } else {
        if (this.qteGlobale == 0) {
          this.produitsFactureFrais.forEach(element => {
            element.qte_apres = 0;
          });
        } else {
          if (this.qteGlobale < this.maxglobal) {
            let length = this.produitsFactureFrais.length;
            let reduction = parseFloat(this.qteGlobale) / length;
            this.produitsFactureFrais.forEach(element => {
              element.qte_apres = parseFloat(element.initial_apres_avoir);
              element.qte_apres =
                parseFloat(element.qte_apres_avoir) - reduction;
              element.qte_apres = element.qte_apres.toFixed(2);
            });
          } else {
            if (this.qteGlobale == this.maxglobal) {
              this.produitsFactureFrais.forEach(element => {
                element.qte_apres = element.qte_apres_avoir;
              });
            }
          }
        }
      }
    },
    hideModal(ref) {
      this.error = [];
      this.loading = false;
      this.loadingCreate;
      this.disabledButton = false;
      this.qteGlobale = 0;
      this.qteGlobaleAvoir = 0;
      this.maxglobal = 0;
      this.unite = null;
      this.$refs[ref]?.hide();
    },

    async handleCreateAvoir() {
      this.loadingCreate = true;
      let products = this.produitsFactureFrais.filter(
        item => item.disabled == false && parseFloat(item.qte_apres) != 0
      );
      if (products.length == 0) {
        this.loadingCreate = false;
        this.error =
          'Aucune facture avoir à générer \n Les quantités à avoir doit être supèrieur à 0';
      } else {
        this.error = null;
        let bodyFormData = {
          vendeur: this.item.vendeur_id,
          id_facture: this.item.id,
          produits: products,
          date: this.date
        };
        const response = await this.createAvoirMensuel({
          item: this.item,
          bodyFormData: bodyFormData
        });
        if (response.success) {
          this.loadingCreate = false;
          this.hideModal('AvoirFacture' + this.index);
        } else {
          this.loadingCreate = false;
          this.error = response.error;
        }
      }
    }
  },
  components: {
    // Card: () => import('@/views/component/card.vue'),
  }
};
</script>

<style lang="scss" scoped>
.avoir-facture-frais {
  position: absolute;
  right: 11px;
  display: flex;
}
</style>
<style lang="scss">
.button-export-style-avoir {
  color: #e4261b;
  &:hover,
  &:focus {
    color: #e4261b;
  }
}
.cutsom-modal-bootstrap-frais .modal-dialog {
  top: 1% !important;
  max-width: 60% !important;
  margin: auto;
  #info-wrap {
    overflow: hidden;
  }
  .type-doc-class > div {
    display: flex;
  }
}

@media screen and (max-width: 925px) {
  .cutsom-modal-bootstrap-frais .modal-dialog {
    top: 1%;
    max-width: 60%;
    margin: auto;
  }
}
</style>
